// extracted by mini-css-extract-plugin
export var fieldSection = "ReturnsForm-module--fieldSection--ePHKj";
export var formLabel = "ReturnsForm-module--formLabel--GZGI2";
export var formInput = "ReturnsForm-module--formInput--oSCpX";
export var invalid = "ReturnsForm-module--invalid--35SRZ";
export var formSubmit = "ReturnsForm-module--formSubmit--RfLxf";
export var mobileGrid = "ReturnsForm-module--mobileGrid--tpVv0";
export var mobileFormField = "ReturnsForm-module--mobileFormField--NRCMr";
export var stretchFormField = "ReturnsForm-module--stretchFormField--p-GT+";
export var spinnerWrapper = "ReturnsForm-module--spinnerWrapper--hPvqH";
export var error = "ReturnsForm-module--error--esVWB";
export var errorMsg = "ReturnsForm-module--errorMsg--YL82y";
export var buttonWrapp = "ReturnsForm-module--buttonWrapp--nFvfM";
export var dialogContent = "ReturnsForm-module--dialogContent--RB+Lh";
export var thankYou = "ReturnsForm-module--thankYou--UIuPZ";